
import { mapGetters,mapActions } from 'vuex'
import productData from '@/data/products.json'
  export default {
    name: "MoreInfoModal",
    props: {
        id: {
            default: '25',
            type: String,
        },
        form: {
            default: '120included',
            type: String,
        },
        virtual: {
            default: 'false',
            type: String,
        }
    },
    data() {
        return {
            isActive: this.active !== ''
        }
    },
    computed: {
        ...mapGetters({
            activeModal : 'pages/getActiveModal',
        }),
        product() {
            const id = Number(this.$props.id)
            // eslint-disable-next-line prefer-const
            let product = productData.find((course) => {
                return course.id === parseInt(id)
            })
                  // console.log(obj)
            product.course_attributes = this.$options.filters.courseFeatures(
                'en',
                product.course.hours,
                product.type
            )
            /* eslint-disable */
            product.course_modules = this.$options.filters.courseModules(
                this.$props.virtual !== 'false',
                product.course.hours,
                product.type
            )
            product.priceData = this.$options.filters.priceData(product)
            product.onSale = product.priceData.saleType !== 'none'
            product.rand = this.rand+this.$props.form
            console.log('ModalProductInfo',product)
            return product
        },
        rand(){
           return Math.floor(Math.random() * 100)
        }
    },
    methods:{
        ...mapActions({
            updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
        }),
        updateModal(id){
            // const newId = this.$nuxt.$options.filters.convertModal(id)
            this.updateActiveModal(id)
            console.log('Active Modal: ',this.activeModal)
        },
        hideModal(){
            this.updateActiveModal(null)
            console.log('Active Modal: ',this.activeModal)
        }
    },

  }
  