

import SsrCarousel from 'vue-ssr-carousel'
// eslint-disable-next-line no-unused-vars
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
import { mapGetters,mapActions } from 'vuex'
import productData from '@/data/products.json'
  export default {
    name: "CourseCardsOnline",
    components: { SsrCarousel },
    props: {
        region: {
            default: 'uk',
            type: String,
        },
        count: {
            default: 2,
            type: Number,
        },
        content: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            isActive: this.active !== ''
        }
    },
    computed: {
        ...mapGetters({
            activeModal : 'pages/getActiveModal',
        }),
        product120() {
            // eslint-disable-next-line prefer-const
            let product = productData.find((course) => {
                return course.id === parseInt(25)
            })
                  // console.log(obj)
            product.course_attributes = this.$options.filters.courseFeatures(
                'en',
                product.course.hours,
                product.type
            )
            /* eslint-disable */
            product.course_modules = this.$options.filters.courseModules(
                this.$props.virtual === 'true',
                product.course.hours,
                product.type
            )
            product.priceData = this.$options.filters.priceData(product)
            product.onSale = product.priceData.saleType !== 'none'
            console.log('product120',product)
            return product
        },
        product200() {
            // eslint-disable-next-line prefer-const
            let product = productData.find((course) => {
                return course.id === parseInt(10183)
            })
                  // console.log(obj)
            product.course_attributes = this.$options.filters.courseFeatures(
                'en',
                product.course.hours,
                product.type
            )
            /* eslint-disable */
            product.course_modules = this.$options.filters.courseModules(
                this.$props.virtual === 'true',
                product.course.hours,
                product.type
            )
            product.priceData = this.$options.filters.priceData(product)
            product.onSale = product.priceData.saleType !== 'none'
            console.log('product200',product)
            return product
        },
    },
    methods:{
        ...mapActions({
            updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
        }),
        updateModal(id){
            // const newId = this.$nuxt.$options.filters.convertModal(id)
            this.updateActiveModal(id)
            console.log('Active Modal: ',this.activeModal)
        },
        hideModal(){
            this.updateActiveModal(null)
            console.log('Active Modal: ',this.activeModal)
        }
    },
    render() {
      const slot = this.$slots.default
      //  return slot.text.replace(/,/g, "")
      return slot
    }

  }
  