
import productData from '@/data/products.json'
export default {
  name: 'Price',
  props: {
    id: {
      default: "",
      type: String,
    },
    white: {
      default: "false",
      type: String,
    },
    from: {
      default: ":",
      type: String,
    },
    break: {
      default: ":",
      type: String
    },
    rrp: {
      default: "false",
      type: String
    }
  },
  data() {
    return {
      // product:{},
      //  pageid: '',
    }
  },
  computed:{
    priceData() {
      const pid =  this.id
    // this.pageid = this.$store.state.pages.page.pageId
      const product = productData.find((o) => {
        return o.id.toString() === pid
      })
      if(product){
        console.log('PRODUCT',product)
        return this.$options.filters.priceData(
          product,
        )
      }
      return null
    },
  }, 
}
